<template>
  <div class="row">
    <div
      :class="{
        [$style.card__register]: true
      }"
    >
      <div
        :class="{
          [$style.card__register__image]: true,
        }"
      >
        <img
          src="@/assets/images/logo.svg"
          width="250"
          height="auto"
        />
      </div>

      <div class="pbz-font subtitle-md-medium mt-5 text-left" style="color: #1a1a1a;">
        Kata Sandi Baru
      </div>

      <div v-for="(item, index) in formError" :key="index">
        <div
          v-for="(error, indexError) in item.error_message"
          :key="indexError"
          class="pbz-font body-sm-regular text-left mt-2"
          style="color: #ee4426;"
        >
          {{ error }} <br />
        </div>
      </div>

      <div class="" style="margin-top: 5rem;">
        <a-form-model ref="ruleForm" :model="form" @submit.prevent="handleSubmit">
          <a-form-model-item
            prop="password"
            :class="{
              [$style.container__form]: true,
              ['mb-3']: checkFormHaveError('password'),
            }"
            :rules="[{
              required: true,
              validator: passwordValidator,
            }]"
          >
            <a-input-password
              v-model="form.password"
              :class="{
                [$style.container__form__input]: true,
              }"
            />

            <label
              :class="{
                [$style.container__form__label]: true,
              }"
              class="pbz-font overline-normal"
              style="color: #999999 !important"
            >
              <span> {{ $t('auth.password') }} </span>
            </label>
          </a-form-model-item>

          <a-form-model-item
            prop="confirm_password"
            :class="{
              [$style.container__form]: true,
              ['mb-3']: checkFormHaveError('confirm_password'),
            }"
            :rules="[{
              required: true,
              validator: confirmPasswordValidator,
            }]"
          >
            <a-input-password
              v-model="form.confirm_password"
              :class="{
                [$style.container__form__input]: true,
              }"
            />

            <label
              :class="{
                [$style.container__form__label]: true,
              }"
              class="pbz-font overline-normal"
              style="color: #999999 !important"
            >
              <span> {{ $t('auth.confirmPassword') }} </span>
            </label>
          </a-form-model-item>

          <a-button
            size="large"
            class="pbz-font body-md-bold text-center w-100 mt-3"
            :class="{
              [$style.button__submit]: true,
            }"
            html-type="submit"
          >
            <div v-if="!loading">
              Perbarui Kata Sandi
            </div>
            <div v-if="loading">
              <a-icon type="loading" /> Loading
            </div>
          </a-button>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script>
import { requestOtp } from '@/api/user'

export default {
  name: 'CuiResetPassword',
  data () {
    return {
      form: {
        password: '',
        confirm_password: '',
      },
      passwordError: {
        field: null,
        error_message: [],
      },
      confirmPasswordError: {
        field: null,
        error_message: [],
      },
      passwordValidator: (rule, value, callback) => {
        const { field } = rule
        this.passwordError.field = field

        const findErrorLengthMessage = this.checkExistingError(
          this.passwordError.error_message,
          this.$t('auth.minimumLengthPassword'),
        )
        const findErrorMessageOneUppercas = this.checkExistingError(
          this.passwordError.error_message,
          this.$t('auth.atLeastOneUppercase'),
        )
        const findErrorMessageOneNumber = this.checkExistingError(
          this.passwordError.error_message,
          this.$t('auth.atLeastOneNumber'),
        )
        const findErrorMessageSpecialChar = this.checkExistingError(
          this.passwordError.error_message,
          this.$t('auth.atLeastOneSpecialChar'),
        )

        if (!value) {
          !findErrorLengthMessage &&
            this.passwordError.error_message.push(
              this.$t('auth.minimumLengthPassword'),
            )
          !findErrorMessageOneUppercas &&
            this.passwordError.error_message.push(
              this.$t('auth.atLeastOneUppercase'),
            )
          !findErrorMessageOneNumber &&
            this.passwordError.error_message.push(
              this.$t('auth.atLeastOneNumber'),
            )
          !findErrorMessageSpecialChar &&
            this.passwordError.error_message.push(
              this.$t('auth.atLeastOneSpecialChar'),
            )
          return callback(this.$t('auth.minimumLengthPassword'))
        }

        const patternLength = /^.{6,}$/
        // const findErrorLengthMessage = this.passwordError.error_message.find(item => item === this.$t('auth.minimumLengthPassword'))

        if (!patternLength.test(value)) {
          !findErrorLengthMessage &&
            this.passwordError.error_message.push(
              this.$t('auth.minimumLengthPassword'),
            )
          return callback(this.$t('auth.minimumLengthPassword'))
        } else {
          const newMessage = []
          this.passwordError.error_message.map((item) => {
            if (item !== this.$t('auth.minimumLengthPassword')) {
              newMessage.push(item)
            }
          })

          this.passwordError.error_message = newMessage
        }

        const patternOneUppercase = /(?=.*[A-Z])/
        // const findErrorMessageOneUppercas = this.passwordError.error_message.find(item => item === this.$t('auth.atLeastOneUppercase'))

        if (!patternOneUppercase.test(value)) {
          !findErrorMessageOneUppercas &&
            this.passwordError.error_message.push(
              this.$t('auth.atLeastOneUppercase'),
            )
          return callback(this.$t('auth.atLeastOneUppercase'))
        } else {
          const newMessage = []
          this.passwordError.error_message.map((item) => {
            if (item !== this.$t('auth.atLeastOneUppercase')) {
              newMessage.push(item)
            }
          })
          this.passwordError.error_message = newMessage
        }

        const patternNumber = /(?=.*[0-9])/
        // const findErrorMessageOneNumber = this.passwordError.error_message.find(item => item === this.$t('auth.atLeastOneNumber'))

        if (!patternNumber.test(value)) {
          !findErrorMessageOneNumber &&
            this.passwordError.error_message.push(
              this.$t('auth.atLeastOneNumber'),
            )
          return callback(this.$t('auth.atLeastOneNumber'))
        } else {
          const newMessage = []
          this.passwordError.error_message.map((item) => {
            if (item !== this.$t('auth.atLeastOneNumber')) {
              newMessage.push(item)
            }
          })

          this.passwordError.error_message = newMessage
        }

        const patternSpecialChar = /[`!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/

        if (!patternSpecialChar.test(value)) {
          !findErrorMessageSpecialChar &&
            this.passwordError.error_message.push(
              this.$t('auth.atLeastOneSpecialChar'),
            )
          return callback(this.$t('auth.atLeastOneSpecialChar'))
        } else {
          const newMessage = []
          this.passwordError.error_message.map((item) => {
            if (item !== this.$t('auth.atLeastOneSpecialChar')) {
              newMessage.push(item)
            }
          })

          this.passwordError.error_message = newMessage
        }

        if (this.passwordError.error_message.length) {
          return callback('error')
        } else {
          return callback()
        }
      },
      confirmPasswordValidator: (rule, value, callback) => {
        const { field } = rule
        this.confirmPasswordError.field = field
        const password = this.form.password

        const findErrorMessagePasswordNotMatch = this.checkExistingError(
          this.confirmPasswordError.error_message,
          this.$t('auth.passwordNotMatch'),
        )
        if (value !== password || !value) {
          !findErrorMessagePasswordNotMatch &&
            this.confirmPasswordError.error_message.push(
              this.$t('auth.passwordNotMatch'),
            )
          return callback(this.$t('auth.passwordNotMatch'))
        } else {
          this.confirmPasswordError.error_message = []
          return callback()
        }
      },
    }
  },
  computed: {
    loading() {
      return this.$store.state.loading
    },
    formError() {
      if (
        !this.passwordError.error_message?.length &&
        !this.confirmPasswordError.error_message?.length
      ) {
        return []
      }

      const data = []
      !!this.passwordError.error_message?.length &&
        data.push(this.passwordError)
      !!this.confirmPasswordError.error_message?.length &&
        data.push(this.confirmPasswordError)

      return data
    },
  },
  created() {
    this.verifyToken()
  },
  methods: {
    checkFormHaveError(paramater) {
      return this.formError.find((item) => item.field === paramater)
    },
    checkExistingError(errorLog = [], stringParam = '') {
      return errorLog.find((item) => item === stringParam)
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async valid => {
        if (!valid) return

        await requestOtp({
          type: 'setup-password',
          data: {
            password: this.form.password,
            token: this.$store.state.user.accessToken || this.$route.query.token,
            username: this.$store.state.user.username || this.$route.query.username,
          },
        })
        .then(({ data }) => {
          this.$store.state.user.accessToken = data.access_token
          this.$router.push({ path: '/auth/forgot-password/success' })
        })
        .catch((err) => {
          this.$notification.error({
            message: err,
          })
        })
      })
    },
    async verifyToken() {
      await requestOtp({
        type: 'verify-token',
        data: {
          token: this.$store.state.user.accessToken || this.$route.query.token,
          username: this.$store.state.user.username || this.$route.query.username,
        },
      })
      .then(({ data: { data: response } }) => {
        this.$notification.success({
          message: response?.message,
        })
      })
      .catch((err) => {
        this.$notification.error({
          message: err?.response?.data?.message,
        })
      })
    },
  },
}
</script>

<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>
