var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{class:{
      [_vm.$style.card__register]: true
    }},[_c('div',{class:{
        [_vm.$style.card__register__image]: true,
      }},[_c('img',{attrs:{"src":require("@/assets/images/logo.svg"),"width":"250","height":"auto"}})]),_c('div',{staticClass:"pbz-font subtitle-md-medium mt-5 text-left",staticStyle:{"color":"#1a1a1a"}},[_vm._v(" Kata Sandi Baru ")]),_vm._l((_vm.formError),function(item,index){return _c('div',{key:index},_vm._l((item.error_message),function(error,indexError){return _c('div',{key:indexError,staticClass:"pbz-font body-sm-regular text-left mt-2",staticStyle:{"color":"#ee4426"}},[_vm._v(" "+_vm._s(error)+" "),_c('br')])}),0)}),_c('div',{staticStyle:{"margin-top":"5rem"}},[_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-model-item',{class:{
            [_vm.$style.container__form]: true,
            ['mb-3']: _vm.checkFormHaveError('password'),
          },attrs:{"prop":"password","rules":[{
            required: true,
            validator: _vm.passwordValidator,
          }]}},[_c('a-input-password',{class:{
              [_vm.$style.container__form__input]: true,
            },model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('label',{staticClass:"pbz-font overline-normal",class:{
              [_vm.$style.container__form__label]: true,
            },staticStyle:{"color":"#999999 !important"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('auth.password'))+" ")])])],1),_c('a-form-model-item',{class:{
            [_vm.$style.container__form]: true,
            ['mb-3']: _vm.checkFormHaveError('confirm_password'),
          },attrs:{"prop":"confirm_password","rules":[{
            required: true,
            validator: _vm.confirmPasswordValidator,
          }]}},[_c('a-input-password',{class:{
              [_vm.$style.container__form__input]: true,
            },model:{value:(_vm.form.confirm_password),callback:function ($$v) {_vm.$set(_vm.form, "confirm_password", $$v)},expression:"form.confirm_password"}}),_c('label',{staticClass:"pbz-font overline-normal",class:{
              [_vm.$style.container__form__label]: true,
            },staticStyle:{"color":"#999999 !important"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('auth.confirmPassword'))+" ")])])],1),_c('a-button',{staticClass:"pbz-font body-md-bold text-center w-100 mt-3",class:{
            [_vm.$style.button__submit]: true,
          },attrs:{"size":"large","html-type":"submit"}},[(!_vm.loading)?_c('div',[_vm._v(" Perbarui Kata Sandi ")]):_vm._e(),(_vm.loading)?_c('div',[_c('a-icon',{attrs:{"type":"loading"}}),_vm._v(" Loading ")],1):_vm._e()])],1)],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }