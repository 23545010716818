<template>
  <div class="text-center mx-auto">
    <cui-reset-password class="mb-3 mt-3 pt-3" />
  </div>
</template>
<script>
import CuiResetPassword from '@/components/cleanui/system/Auth/ResetPassword'
export default {
  components: {
    CuiResetPassword,
  },
}
</script>
